import React from 'react';
import styled from '../../styled';
import { Link } from 'gatsby';
import { Typography } from '@material-ui/core';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { SignupButtonWithChoice } from '../SignupButton';
import { COLORS } from '../../themes/color';

type Variant = 'screenshot' | 'illustration';
type IProps = {
  image: string;
  variant: Variant;
  orientation: 'left' | 'right' | 'straight';
  headline: React.ReactElement;
  body: React.ReactElement;
  id?: string;
};

export const Label = styled('span')`
  background-color: ${COLORS.PAPER_DARK};
  display: inline-block;
  color: #555;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: ${(p) => p.theme.shape.borderRadius / 2}px;
  font-size: 11px;
  padding: 4px 9px;
  margin-bottom: 16px;
  font-weight: 900;

  @media (max-width: 800px) {
    font-size: 16px !important;
  }
`;

const toGrid = (orientation: IProps['orientation']) => {
  if (orientation === 'left') {
    return '1fr 1fr';
  }
  if (orientation === 'right') {
    return '1fr 1fr';
  }
  return '1fr 1fr';
};

const Grid = styled<'div', { orientation: IProps['orientation'] }>('div')`
  display: grid;
  grid-template-columns: ${(p) => toGrid(p.orientation)};
  margin: 0 auto;
  grid-column-gap: ${(p) => p.theme.spacing(10)}px;
  padding: ${(p) => p.theme.spacing(5)}px;
  align-items: center;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-column-gap: ${(p) => p.theme.spacing(5)}px;
    padding: ${(p) => p.theme.spacing(2)}px;
  }
`;

const Container = styled<
  'div',
  { variant: Variant; orientation: IProps['orientation'] }
>('div')`
  background-color: #fff;
  padding: ${(p) => p.theme.spacing(4)}px 0;
  position: relative;
  max-width: 1200px;
  ${(p) => p.orientation === 'right' && `right: -15%;`}
  ${(p) => p.orientation === 'left' && `left: -15%;`}
  overflow: inherit;
  color: #555;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 900;
    letter-spacing: -1px;
  }

  h3 {
    font-size: 32px;
    color: #000;
  }

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(1)}px 0;
    left: 0;
    right: 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: ${(p) => p.theme.typography.h6.fontSize};
    }
  }
`;

const toDegrees = (orientation: IProps['orientation']) => {
  if (orientation === 'left') {
    return -2;
  }
  return 2;
};

const SectionScreenshot = styled<'img', { orientation: IProps['orientation'] }>(
  'img'
)`
  width: 150%;
  transform: rotate(${(p) => toDegrees(p.orientation)}deg);
  margin: ${(p) => p.theme.spacing(5)}px auto;
  display: block;
  background-color: white;
  border: 1px solid #ebebf0;
  border-radius: ${(p) => p.theme.custom.borderRadius.unit * 3}px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

  @media (max-width: 800px) {
    max-width: 90%;
  }
`;

const SectionIllustration = styled('img')`
  margin: ${(p) => p.theme.spacing(5)}px auto;
  display: block;
  height: 550px;

  @media (max-width: 800px) {
    max-width: 90%;
  }
`;

const Section = ({
  image,
  variant,
  orientation,
  headline,
  body,
  ...props
}: IProps) => {
  const isMobile = useMediaQuery('(max-width: 800px)');

  return (
    <Container variant={variant} orientation={orientation} {...props}>
      <Grid orientation={orientation}>
        {orientation === 'left' || isMobile ? (
          <>
            {variant === 'screenshot' && (
              <SectionScreenshot orientation="left" src={image} />
            )}
            {variant === 'illustration' && <SectionIllustration src={image} />}
            <div>
              {headline}
              <br />
              {body}
            </div>
          </>
        ) : (
          <>
            <div>
              {headline}
              <br />
              {body}
            </div>
            {variant === 'illustration' && <SectionIllustration src={image} />}
            {variant === 'screenshot' && (
              <SectionScreenshot orientation="right" src={image} />
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default Section;

export const SegmentationSection = ({
  orientation
}: {
  orientation: 'left' | 'right';
}) => (
  <Section
    image="/images/features/new-segmentation.png"
    variant="screenshot"
    orientation={orientation}
    headline={
      <>
        <Label id="revenue-attribution">Content segmentation</Label>
        <Typography variant="h5" component="h3" gutterBottom>
          Create more profitable content based on top performing segments
        </Typography>
      </>
    }
    body={
      <>
        <Typography paragraph variant="body1">
          Benchmark performance between different content formats, product
          categories, target demographics and more. See which segments are
          driving the most revenue, or have the highest EPCs and RPMs.
          <br />
          <br />
          Create custom content types and tags, and apply them to your content
          portfolio without changing any links. Identify your most profitable
          content strategies in seconds.
        </Typography>
        <br />
        <SignupButtonWithChoice text="Create your segments" />
      </>
    }
  />
);

export const RevenueAttributionSection = ({
  orientation
}: {
  orientation: 'left' | 'right';
}) => (
  <Section
    image="/images/features/new-page-level-reporting.png"
    variant="screenshot"
    orientation={orientation}
    headline={
      <>
        <Label id="revenue-attribution">Content trends</Label>
        <Typography variant="h5" component="h3" gutterBottom>
          Monitor your content portfolio for revenue spikes and drops
        </Typography>
      </>
    }
    body={
      <>
        <Typography paragraph variant="body1">
          Use Affilimate's{' '}
          <Link to="/product/content-analytics/">content analytics</Link> and{' '}
          <Link to="/product/revenue-attribution/">revenue attribution</Link> to
          find which content and products generate the most revenue, across
          affiliate programs.
          <br />
          <br />
          View your content portfolio by the fastest growing or declining
          articles, so you can target which articles need updates today.
        </Typography>
        <br />
        <SignupButtonWithChoice text="Monitor your content" />
      </>
    }
  />
);

export const RevisionsSection = ({
  orientation
}: {
  orientation: 'left' | 'right';
}) => (
  <Section
    image="/images/features/new-revisions.png"
    variant="screenshot"
    orientation={orientation}
    headline={
      <>
        <Label id="testing-monitoring">Revision Tracking</Label>
        <Typography variant="h5" component="h3" gutterBottom>
          Track the impact of content updates on affiliate revenue
        </Typography>
      </>
    }
    body={
      <>
        <Typography paragraph>
          Track how editorial changes affect affiliate revenue, click-through
          rate, and affiliate RPM and EPC on pages across your site, without
          manually setting up experiments each time.
          <br />
          <br />
          Generated automatically. Fits perfectly into your existing workflow.
          And scales effortlessly across your entire portfolio of content.
        </Typography>
        <br />
        <SignupButtonWithChoice text="Track your edits" />
      </>
    }
  />
);

export const AffiliateIntegrationsSection = ({
  orientation
}: {
  orientation: 'left' | 'right';
}) => (
  <Section
    image="/images/features/affiliate-dashboard/affiliate-integrations.png"
    variant="illustration"
    orientation={orientation}
    headline={
      <>
        <Label id="integrations">Integrations</Label>
        <Typography variant="h5" component="p" gutterBottom>
          Integrate with dozens of affiliate networks and programs
        </Typography>
      </>
    }
    body={
      <Typography paragraph variant="body1">
        Save time by logging into one easy-to-use affiliate dashboard that can{' '}
        <strong>aggregate all your reports</strong> into a single, sortable,
        searchable, and groupable format.
        <br />
        <br />
        <Link to="/integrations/">See all integrations</Link>
      </Typography>
    }
  />
);

export const TransactionReportSection = ({
  orientation
}: {
  orientation: 'left' | 'right';
}) => (
  <Section
    image="/images/features/transactions-view.png"
    variant="screenshot"
    orientation={orientation}
    headline={
      <>
        <Label id="affiliate-dashboard">Unified Reporting</Label>
        <Typography variant="h5" component="p" gutterBottom>
          Track all your affiliate commissions in one place
        </Typography>
      </>
    }
    body={
      <Typography paragraph variant="body1">
        Save time by logging into one easy-to-use{' '}
        <Link to="/product/affiliate-dashboard/">affiliate dashboard</Link> that
        can <strong>aggregate all your reports</strong> into a single, sortable,
        searchable, and groupable format.
        <br />
        <br />
        Use our reporting presets, or create your own from scratch.
      </Typography>
    }
  />
);

export const AffiliateDashboardSection = ({
  orientation
}: {
  orientation: 'left' | 'right';
}) => (
  <Section
    image="/images/features/new-transaction-history.png"
    variant="screenshot"
    orientation={orientation}
    headline={
      <>
        <Label id="unified-reporting">Reporting &amp; APIs</Label>
        <Typography variant="h5" component="h3" gutterBottom>
          Unified affiliate revenue reporting and publisher APIs
        </Typography>
      </>
    }
    body={
      <>
        <Typography paragraph variant="body1">
          Take all your detailed affiliate insights and roll them up into
          stunning charts and custom reports you can share.
          <br />
          <br />
          Expand into custom use cases with Affilimate's robust reporting APIs,
          that let you pull your data quickly and into internal systems like
          your data warehouse.
        </Typography>
        <br />
        <SignupButtonWithChoice text="Design your reports" />
      </>
    }
  />
);

export const MultiSiteSection = ({
  orientation
}: {
  orientation: 'left' | 'right';
}) => (
  <Section
    image="/images/features/multi-site-2.png"
    variant="illustration"
    orientation={orientation}
    headline={
      <>
        <Label id="multi-site">Multi-site Support</Label>
        <Typography variant="h5" component="h3" gutterBottom>
          Oversee revenue from multiple websites in one place
        </Typography>
      </>
    }
    body={
      <Typography paragraph>
        Running multiple sites or monitoring a portfolio? Get an at-a-glance
        understanding of how each of your sites are performing in a shared
        dashboard.
        <br />
        <br />
        Collect them in a single workspace, or divide them among several
        workspaces for different clients or companies.
      </Typography>
    }
  />
);

export const HeatmapSection = ({
  orientation
}: {
  orientation: 'left' | 'right';
}) => (
  <Section
    image="/images/features/new-heatmaps.png"
    variant="screenshot"
    orientation={orientation}
    headline={
      <>
        <Label id="heatmaps">Affiliate Heatmaps</Label>
        <Typography variant="h5" component="h3" gutterBottom>
          Affiliate revenue attribution down to the pixel
        </Typography>
      </>
    }
    body={
      <>
        <Typography variant="body1" component="p" paragraph>
          Discover exactly which affiliate links, buttons, images, and
          calls-to-action within your pages are driving conversions (and which
          aren't).
          <br />
          <br />
          Explore color-coded, link-level metrics presented in a simple way
          anyone can turn into an action plan.
          <br />
          <br />
          Then, multiply revenue from existing content by optimizing link
          placement, retailer selection, and get the leverage you need to
          negotiate higher commission rates.
        </Typography>
        <br />
        <SignupButtonWithChoice text="Visualize your conversions" />
      </>
    }
  />
);

export const EmailReportsSection = ({
  orientation
}: {
  orientation: 'left' | 'right';
}) => (
  <Section
    image="/images/homepage-email.jpg"
    variant="screenshot"
    orientation={orientation}
    headline={
      <>
        <Label id="email-reports">Daily Email Reports</Label>
        <Typography variant="h5" component="h3" gutterBottom>
          Monitor your earnings on autopilot
        </Typography>
      </>
    }
    body={
      <Typography paragraph>
        Get a daily email report to help you monitor your properties, top
        content, conversion rate, affiliate networks, and advertisers for
        commissions earned in the previous day.
        <br />
        <br />
        Then dive into the exact conversions through detailed, SKU-level reports
        in Affilimate.
      </Typography>
    }
  />
);

export const AmazonBrokenLinkCheckerSection = ({
  orientation
}: {
  orientation: 'left' | 'right';
}) => (
  <Section
    image="/images/features/link-management.png"
    variant="screenshot"
    orientation={orientation}
    headline={
      <>
        <Label id="amazon-link-checker">Amazon Affiliate Link Checker</Label>
        <Typography variant="h5" component="h3" gutterBottom>
          Stop losing sales to missing or out-of-stock products
        </Typography>
      </>
    }
    body={
      <Typography paragraph variant="body1">
        Automatically check your Amazon affiliate links on a recurring basis and
        get notified if any are broken, out of stock, or have other issues that
        may affect your conversion rate.
      </Typography>
    }
  />
);
