import styled from '../../styled';

const PageHead = styled('div')`
  text-align: center;
  margin: ${(p) => p.theme.spacing(14)}px auto ${(p) => p.theme.spacing(10)}px;
  padding: ${(p) => p.theme.spacing(1)}px;

  @media (max-width: 800px) {
    margin: ${(p) => p.theme.spacing(8)}px auto ${(p) => p.theme.spacing(4)}px;
  }
`;

export default PageHead;
