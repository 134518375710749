import React from 'react';
import withRoot from '../withRoot';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { CookieBar } from '../components/CookieBar';

const Main = ({ children }: { children: React.ReactElement }) => {
  return (
    <div>
      <Navigation />
      <main style={{ overflowX: 'hidden' }}>{children}</main>
      <Footer />
      <CookieBar />
    </div>
  );
};

export default withRoot(Main);
