import React from 'react';
import MainLayout from '../../layouts/main';
import { Typography } from '@material-ui/core';
import { SEO } from '../../components/SEO';
import PageHead from '../../components/PageHead';
import Section, { Label } from '../../components/Section';
import { DemoButton } from '../../components/DemoButton';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/payouts/"
          title="Payout Reporting for Affiliate Publishers"
          description="Streamline your finance processes with affiliate payout reporting and reconciliation."
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '630px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Streamline your finance processes with affiliate payout reporting
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '510px', margin: '24px auto 12px' }}
          >
            Payout reporting simplifies your accounting and finance processes,
            helps you monitor for unpaid commissions, and even calculate payouts
            for subaffiliates.
          </Typography>
          <div style={{ textAlign: 'center', margin: '48px auto 0' }}>
            <DemoButton variant="contained" />
          </div>
          <img
            src="/images/features/payout-reporting.png"
            style={{
              width: '80%',
              maxWidth: '700px',
              margin: '48px auto',
              display: 'block'
            }}
            alt="Payout reporting"
          />
        </PageHead>
        <Section
          image="/images/features/paid-status-reporting.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Paid status reporting</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Track payout statuses down to the commission-level
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Bulk report on the payout statuses of your commissions, and
                easily identify commissions that are overdue for payment by your
                partners.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/payout-report.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Payouts report</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                All your payouts in one, streamlined dashboard
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                See your payouts across affiliate networks and exactly which
                commissions belong to them, without wading through dozens of
                dashboards and trying to unify reporting manually.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/payout-reconciliation.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Payout details</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Individual payout details with fast reconciliation
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Jump into any payout and cross-check the payout amount with the
                commissions reported by the network for that payout.
                <br />
                <br />
                At a glance, see if the numbers match, have a margin of error,
                or are materially different than expected.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/payouts-api.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Payouts API</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Pull payouts and transaction-level payout data by API
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Merge your payout and affiliate transaction data with other
                revenue sources. Incorporate your payouts and transactions into
                custom reports and dashboards within your internal tools,
                including your data warehouse.
              </Typography>
            </>
          }
        />
      </div>
    </MainLayout>
  );
};
